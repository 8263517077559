import axios from 'axios';
import React, { useState } from 'react'

export const APITesting = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
});

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axios.post('/submit-form', formData);
        alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error occurred:', error.message);
        alert('An error occurred while submitting the form.');
    }
};

  return (
    <div>
            <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
                <button type="submit">Submit</button>
            </form>
        </div>
  )
}







// import React, { useEffect, useState } from 'react'

// export const APITesting = () => {

//     const [todos, setTodos] = useState()

//     useEffect(() => {
//         fetch('http://jsonplaceholder.typicode.com/todos')
//         .then(res => res.json())
//         .then((data) => {
//           setTodos(data)
//           console.log(todos)
//         })
//      }, [todos]);
//   return (
//     <div>
//        {todos.map((e) => 
//        <>
//        <h1>{e.id}</h1>
//        <h4>{e.title}</h4>
//        </>
//        )}
//     </div>
//   )
// }
