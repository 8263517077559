
import React, { useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import postData from '../DataToGet/Data'
import { Link } from 'react-router-dom';
import "../AboutUS/About.css"

export const AdminForm = () => {
  const ref = useRef();
  // const [postData, setPostData] = useState({
  //   title: '',
  //   body: ''
  // })
  const data = {
    URL: '',
    BANNER_IMAGE: '',
    SECTION1_TEXT: '',
    SECTION1_CONTENT: '',
    SECTION2_TITLE: '',
    SECTION2_CONTENT: '',
    SECTION3_TITLE: '',
    SECTION3_CONTENT: '',
    SECTION3_HEADING: '',
    TEMPLATE_ID: '',
    files: []
  }
  const [formData, setFormData] = useState(data);
  // const handleInput = (e) => {
  //   setPostData({ ...postData, [e.target.name]: e.target.event })
  // }

  const handleImageChange = (e) => {
    if (e.target.name === 'files') {
      setFormData({
        ...formData,
        [e.target.name]: [...formData.files, e.target.files[0]]
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  console.log("formData", formData);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (let key in formData) {
      if (key === 'files') {
        for (let i = 0; i < formData.files.length; i++) {
          formDataToSend.append('files', formData.files[i]);
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch('http://192.168.1.91:3306/create', {
        method: 'POST',
        body: formDataToSend
      });

      if (response.ok) {
        alert('Files uploaded successfully!');
        // Optionally, reset the form
        setFormData(data);
      } else {
        alert('Failed to upload files.');
        setFormData(data);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setFormData(data);
      alert('Error uploading files. Please try again later.');
    }
  };


  

  return (
    <div >

<div className='mt-5 bg-info py-5'>
            <Tabs
              defaultActiveKey="card-creation"
              id="justify-tab-example"
              className="mb-3"
              fill
            >
              <Tab eventKey="card-creation" title="Card Creation">
              <Container>
        <Row className='border my-4 py-4' style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Col lg={7}>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">

               

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Tempolate ID</Form.Label>
                  <Form.Select aria-label="Select Template ID" required
                      onChange={handleImageChange}
                      type="text"
                      value={formData.TEMPLATE_ID}
                      placeholder="Tempalte ID"
                      name='TEMPLATE_ID'>
                        <option>Select Template ID</option>
                         {postData.map((e) =>
                    
                      <option value="1">{e.id}</option>
                    )}
                      
                     

                    </Form.Select>
                 

                 
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
             

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Card Content</Form.Label>
                  <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.SECTION3_TITLE}
                    placeholder="Card Content"
                    name='SECTION3_TITLE'

                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>



                <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                  <Form.Label>Card Image</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control required type='file' ref={ref} onChange={handleImageChange} name="files" />
                    <Form.Control.Feedback type="invalid">
                      Please select an image.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Card Link</Form.Label>
                  <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.SECTION2_TITLE}
                    placeholder="Card Link name"
                    name='SECTION2_TITLE'

                  />

                  <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                </Form.Group>
              </Row>


              <Button type="submit">Submit form</Button>
            </Form>
          </Col>
        </Row>
      </Container>
               
              </Tab>
              
             
              <Tab eventKey="template"  title="Template Creation">
              <Container>
        <Row className='border my-4 py-4' style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Col lg={7}>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">

                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Main Banner</Form.Label>
                  <Form.Control required type='file' ref={ref} onChange={handleImageChange} name="files" />
                  <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Tempolate ID</Form.Label>
                  <Form.Select aria-label="Select Template ID" required
                      onChange={handleImageChange}
                      type="text"
                      value={formData.TEMPLATE_ID}
                      placeholder="Tempalte ID"
                      name='TEMPLATE_ID'>
                        <option>Select Template ID</option>
                         {postData.map((e) =>
                    
                      <option value="1">{e.id}</option>
                    )}
                      
                     

                    </Form.Select>
                 

                  {/* <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.TEMPLATE_ID}
                    placeholder="Tempalte ID"
                    name='TEMPLATE_ID'

                  /> */}
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Section-1 Heading</Form.Label>
                  <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.SECTION2_TITLE}
                    placeholder="First name"
                    name='SECTION2_TITLE'

                  />

                  <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Section-1 Content</Form.Label>
                  <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.SECTION3_TITLE}
                    placeholder="Last name"
                    name='SECTION3_TITLE'

                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>



                <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                  <Form.Label>Section-1 Image</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control required type='file' ref={ref} onChange={handleImageChange} name="files" />
                    <Form.Control.Feedback type="invalid">
                      Please choose a username.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Section-2 Heading</Form.Label>
                  <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.SECTION2_TITLE}
                    placeholder="First name"
                    name='SECTION2_TITLE'

                  />

                  <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Section-2 Content</Form.Label>
                  <Form.Control
                    required
                    onChange={handleImageChange}
                    type="text"
                    value={formData.SECTION3_TITLE}
                    placeholder="Last name"
                    name='SECTION3_TITLE'

                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>



                <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                  <Form.Label>Section-2 Image</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control required type='file' ref={ref} onChange={handleImageChange} name="files" />
                    <Form.Control.Feedback type="invalid">
                      Please choose a username.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>


              <Button type="submit">Submit form</Button>
            </Form>
          </Col>
        </Row>
      </Container>
               
              </Tab>

            </Tabs>
          </div>
      


    </div>
  )
}
