import React from 'react'

export const MailIcon = () => {
  return (
    <div>
        <img style={{width:36}} src={require('../Assets/Social Media-Icons/Saas-Page-Icon-02.png')}/>

    </div>
  )
}
