import React, { useEffect } from 'react'
import { Button, Carousel, Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import './About.css'
import { Link } from 'react-router-dom'
import Aos from 'aos'

export const AboutUs = () => {

    useEffect(() => {
        Aos.init({
            disable: "phone",
            duration: 700,
            // easing: "ease-out-cubic",
        });
        Aos.refresh();

    }, []);
    return (
        <div>

            <section className='who-are-we text-light' >
                <Container>
                   <div className='who-are-we-content'>
                    <h1 >Who Are We?</h1>
                    <p>
                    We, <b>PiLog Group,</b> are a renowned digital transformation and master data management solution provider across India, USA, Europe and CIS, Middle East North, Qatar, Middle East South, and Belgium. Our spectrum of services includes Digital transformation, data analytics, Data harmonization, Data migration, iSPIR management, Digital governance & Analytical suite (On-premise and cloud-based), Data health assessment, Data archiving and unarchiving, PM Data acquisition, Imaging&Tagging inventory optimization, Physical verification, BPO, Consulting, etc.
                    </p>

                   </div>
                </Container>
            </section>


            <div>
                <Container>
                    <Row className='about-us-frame-1'>
                        <Col>

                            <div className='farme-1-left'>
                                <div data-aos="fade-right" className=''>
                                    <h2>ABOUT US</h2>
                                    <p className='ind-empty d-flex ' ></p>

                                    {/* <h1>ABOUT US</h1> */}
                                    <p>We, <b>PiLog Group,</b> being a pioneer in providing <b>Digital Transformation</b> and <b>Master Data Management (MDM) solutions</b>, enable enterprises to modernize their business strategies. Since our inception in 1996, we have left no stone unturned in transforming DATA into a valuable ASSET. We offer a comprehensive data governance framework focusing on services and key functions of data quality within Supply, Enterprise, & Asset Life Cycle Management.</p>
                                    <Link to=''><Button>Discover Now</Button></Link>
                                </div>

                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img className='w-100' src={require('../Assets/AboutUS/market-now-side.jpg')} alt='' />
                            </div>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                </Container>
            </div>


            
            {/* Our Products */}

            {/* <div>
                <Container>
                    <div className='our-products'>
                        <h1>OUR PRODUCTS</h1>
                    </div>
                    <Carousel >
                        <Carousel.Item className='py-4'>

                            <Row className='our-products-frame4'>
                                <Col lg={8}>
                                    <div>
                                        <h3 className='text-primary'>MDRM: Master data Record manager</h3>
                                        <p>PiLog MDRM centralises all your critical data, from materials and services to vendors, customers, assets, employees, and business partners. No more siloed information, no more manual processes.</p>
                                        <p>
                                            It is a diversified and automated data management system that provides liberty from complex operations and enhances time management.
                                        </p>

                                        <Link to=""><Button>Explore now!</Button></Link>
                                    </div>

                                </Col>
                                <Col>
                                    <div>
                                        <img className='w-100' src={require('../Assets/AboutUS/fixed-asset-img.png')} alt='' />

                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item className='py-4'>

                            <Row className='our-products-frame4'>
                                <Col lg={8}>
                                    <div>
                                        <h3 className='text-primary'>Lean Data Governance:</h3>
                                        <p>
                                            PiLog Lean Data Governance is the world’s first subscription-based, zero maintenance cloud-based product that can manage essential data sets, including materials, vendors, services, customers, and assets.
                                        </p>
                                        <ul>
                                            <li>
                                                Helps businesses establish effective governance practices, streamline Master data governance on the cloud, and gain greater visibility and control over their critical business information.
                                            </li>
                                            <li>
                                                ERP agnostic/heterogenous
                                            </li>
                                        </ul>

                                        <Link to=""><Button>Explore now!</Button></Link>
                                    </div>

                                </Col>
                                <Col>
                                    <div>
                                        <img className='w-100' src={require('../Assets/AboutUS/Transports-&-logistics-image.jpg')} alt='' />

                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>

                </Container>
            </div> */}

            <div>
                <Container>
                    <Row>
                        <Col>
                            <div class="why-chooseus-card-container">
                                <div class="why-chooseus-card">
                                    <div class="front-content">
                                        <p>Holistic Approach</p>
                                    </div>
                                    <div class="content">
                                        <p class="heading">Holistic Approach</p>
                                        <p>
                                        At PiLog, we understand that data management is not just about one aspect but the entire spectrum. With our holistic approach, we cover everything from data creation to retirement, ensuring that you have complete control over your data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="why-chooseus-card-container">
                                <div class="why-chooseus-card">
                                    <div class="front-content">
                                        <p>Industry Expertise</p>
                                    </div>
                                    <div class="content">
                                        <p class="heading">Industry Expertise</p>
                                        <p>
                                        What sets us apart is our team of industry experts who understand the intricacies of various sectors. This knowledge translates into practical solutions, giving you an edge over your competitors. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="why-chooseus-card-container">
                                <div class="why-chooseus-card">
                                    <div class="front-content">
                                        <p>Proven Track Record</p>
                                    </div>
                                    <div class="content">
                                        <p class="heading">Proven Track Record</p>
                                        <p>
                                        Our proven track record speaks for itself. Organizations across industries trust us to enhance their data capabilities, as evidenced by our many customer testimonials.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="why-chooseus-card-container">
                                <div class="why-chooseus-card">
                                    <div class="front-content">
                                        <p>Continuous Innovation</p>
                                    </div>
                                    <div class="content">
                                        <p class="heading">Continuous Innovation</p>
                                        <p>
                                        At PiLog, we don't rest on our laurels. Our continuous innovation and R&D efforts ensure that we're always at the forefront of data excellence.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>

            </div>

            <div className='my-4 p-3 text-light solutions'>
        <div data-aos="fade-right" className='d-flex ind-rec justify-content-center align-items-center'>
          <h2>Top-notch solutions for end-to-end data management</h2>
          <p className='ind-empty d-flex ' ></p>

          <p>Explore PiLog’s products powered by Data Science (AI& ML) </p>
        </div>
          <div className='mt-5'>
            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3"
              fill
            >
              <Tab eventKey="home" title="Master data Record manager (MDRM)">
                <Container>
                <Row className='our-products-frame4'>
                  <Col lg={8}>
                    <div>
                      <h3 className='our-product-heading'>Master data Record manager</h3>
                      <p>
                      Accelerate your business’ initiatives with MDRM. Leverage master data governance solution for material, service, vendor, customer, asset, employee, location, and business partner with embedded data quality management. 
                      </p>
                      <ul>
                        <li>
                        Multi-domain, Multi-tenant and Multi-lingual system with sophisticated UI/UX 
                        </li>
                        <li>
                        Integration with various ERPs-IBM Maximo, Big Data Hadoop, on-cloud, C4C, Salesforce, Microsoft Dynamics Oracle EBS, SAP Hybris, SAP ERP, SAP Ariba, and SAP S/4 Hana.
                        </li>
                        <li>Integration with PiLog Preferred add-ons-PPO, PPR and PPH.</li>
                      </ul>

                      <Link to=""><Button>Explore now!</Button></Link>
                    </div>

                  </Col>
                  <Col>
                    <div>
                      <img className='w-100' src={require('../Assets/AboutUS/fixed-asset-img.png')} alt='' />

                    </div>
                  </Col>
                </Row>
                </Container>
                
              </Tab>
              <Tab eventKey="dqb" title="DQB (DATA QUALITY HUB)">
                <Container>
                <Row className='our-products-frame4'>
                  <Col lg={8}>
                    <div>
                      <h3 className='our-product-heading'>DQB (DATA QUALITY HUB)</h3>
                      <p>
                      Extract master data seamlessly from large datasets that are heterogenous and intertwined across multiple applications such as Web, SaaS, ERP, CRM, etc. Utilize high-quality data from this single point of access without hassles.  
                      </p>
                      <ul>
                        <li>
                        Cleansing
                        </li>
                        <li>Standardization</li>
                        <li>Classification</li>
                        <li>Enrichment</li>
                      </ul>

                      <Link to=""><Button>Explore now!</Button></Link>
                    </div>

                  </Col>
                  <Col>
                    <div>
                      <img className='w-100' src={require('../Assets/AboutUS/fixed-asset-img.png')} alt='' />

                    </div>
                  </Col>
                </Row>
                </Container>
                
              </Tab>
              <Tab eventKey="lean-data-governance" title="Lean Data Governance">
                <Container>
                <Row className='our-products-frame4'>
                  <Col lg={8}>
                    <div>
                      <h3 className='our-product-heading'>Lean Data Governance</h3>
                      <p>
                      Accelerate digital transformation with robust lean master data governance policies and procedures tailored to meet your organization’s objectives and processes. Eliminate data inconsistencies,  have greater visibility, improve trust, predict costs, and ensure regulatory compliance with master data management on the SaaS platform. 
                      </p>
                      <ul>
                        <li>
                        Identifying critical data elements
                        </li>
                        <li>
                        Establish robust data governance policies and procedures
                        </li>
                        <li>
                        Streamlining master data management on cloud
                        </li>
                      </ul>

                      <Link to=""><Button>Explore now!</Button></Link>
                    </div>

                  </Col>
                  <Col>
                    <div>
                      <img className='w-100' src={require('../Assets/AboutUS/fixed-asset-img.png')} alt='' />

                    </div>
                  </Col>
                </Row>
                </Container>
               
              </Tab>
              <Tab eventKey="digital-transformation" title="Digital transformation">
                <Container>
                <Row className='our-products-frame4'>
                  <Col lg={8}>
                    <div>
                      <h3 className='our-product-heading'>Digital transformation</h3>
                      <p>
                      In today’s digital-first landscape, Businesses must be agile. Streamline your organization’s operations and internal processes; build strong relationships with stakeholders; and unlock new avenues for growth using digital transformation.
                      </p>
                      <ul>
                        <li>
                        Clear understanding of business objectives and customer needs
                        </li>
                        <li>Leverage data analytics and automation technologies</li>
                       
                       
                      </ul>

                      <Link to=""><Button>Explore now!</Button></Link>
                    </div>

                  </Col>
                  <Col>
                    <div>
                      <img className='w-100' src={require('../Assets/AboutUS/fixed-asset-img.png')} alt='' />

                    </div>
                  </Col>
                </Row>
                </Container>
               
              </Tab>
              <Tab eventKey="idqm" title="Intelligence Data Quality Management">
                <Container>
                <Row className='our-products-frame4'>
                  <Col lg={8}>
                    <div>
                      <h3 className='our-product-heading'>Intelligence Data Quality Management</h3>
                      <p>
                        Automate the process of standardization, cleansing, and management of unstructured data through Auto-structured Algorithms (ASA) built on PiLog’s taxonomy and the catalog repositories of master data records.
                    </p>
                      <ul>
                        <li>
                        PiLog Preferred Records (PPR)
                        </li>
                        <li>PiLog Preferred Ontology (PPO)</li>
                        <li>PiLog Preferred Hierarchy (PPH)</li>
                       
                      </ul>

                      <Link to=""><Button>Explore now!</Button></Link>
                    </div>

                  </Col>
                  <Col>
                    <div>
                      <img className='w-100' src={require('../Assets/AboutUS/fixed-asset-img.png')} alt='' />

                    </div>
                  </Col>
                </Row>

                </Container>
               
              </Tab>

            </Tabs>
          </div>
        
      </div>

           

            {/* OUR BENEFITS */}

            <div className='my-4 py-3'>

                <Container>
                    {/* <div className='what-we-do'>
                    <h1>OUR BENEFITS</h1>
                </div> */}
                    <div data-aos="fade-right" className='d-flex ind-rec justify-content-center align-items-center'>
                        <h2>OUR BENEFITS</h2>
                        <p className='ind-empty d-flex ' ></p>
                    </div>
                    <Row className='mt-5'>
                        <Col lg={3} md={6} xs={12} className='mt-2'>
                            <div>
                                <img className='w-100' src={require('../Assets/AboutUS/Healthcare-image.jpg')} alt='' />

                            </div>
                            <div className='bg-danger text-white mt-3 py-3 text-left'>
                                <h6>
                                    Multi-Domai
                                </h6>
                                <p>(Material, Vendor, Customer, Service, Equipment, HR, Finance)</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mt-2'>

                            <div className='bg-primary text-white mb-3 py-3 text-left'>
                                <h6>
                                    Multi-Domai
                                </h6>
                                <p>(Material, Vendor, Customer, Service, Equipment, HR, Finance)</p>
                            </div>
                            <div>
                                <img className='w-100' src={require('../Assets/AboutUS/Healthcare-image.jpg')} alt='' />

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mt-2'>
                            <div>
                                <img className='w-100' src={require('../Assets/AboutUS/Healthcare-image.jpg')} alt='' />

                            </div>
                            <div className='bg-success text-white mt-3 py-3 text-left'>
                                <h6>
                                    Multi-Domai
                                </h6>
                                <p>(Material, Vendor, Customer, Service, Equipment, HR, Finance)</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mt-2'>

                            <div className='bg-warning text-white mb-3 py-3 text-left'>
                                <h6>
                                    Multi-Domai
                                </h6>
                                <p>(Material, Vendor, Customer, Service, Equipment, HR, Finance)</p>
                            </div>
                            <div>
                                <img className='w-100' src={require('../Assets/AboutUS/Healthcare-image.jpg')} alt='' />

                            </div>
                        </Col>
                    </Row>



                </Container>
            </div>

            <div>
                <Container>
                    <div>
                        <h5>Our Unique Selling Points (USPs)</h5>
                    </div>
                    <Row className='mt-4' >

                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Co-Sell_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Global Footprint:</h6>
                                    <p>
                                        With a presence in multiple countries, we understand the nuances of data management across diverse cultures and industries.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Co-Sell_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Innovative Products</h6>
                                    <p>
                                        Our Material Master Taxonomy 4.0 for SAP Master Data Governance is a testament to our commitment to cutting-edge solutions.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Co-Sell_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Agility:</h6>
                                    <p>
                                        We adapt swiftly to changing landscapes. Our agility allows us to stay ahead of the curve and anticipate our clients’ needs.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Co-Sell_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Customer-Centric Approach:</h6>
                                    <p>
                                        We listen to our clients, understand their pain points, and tailor solutions that address their specific challenges.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>


            <div className='my-5'>
                <Container>
                    <div>
                        <h5>HOW WE STAND OUT FROM OTHERS?</h5>
                        <h6>Standing Out from Competitors</h6>
                    </div>
                    <Row >

                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Co-Sell_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Holistic Approach:</h6>
                                    <p>
                                        We don’t just focus on one aspect of data management; we cover the entire spectrum. From data creation to retirement, we’ve got you covered.
                                    </p>
                                </div>
                            </div>

                        </Col>
                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Service_Level_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>


                                    <h6>Industry Expertise:</h6>
                                    <p>
                                        Our team comprises domain experts who understand the intricacies of various sectors. This knowledge translates into practical solutions.
                                    </p>
                                </div>
                            </div>


                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Service_Level_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Proven Track Record:</h6>
                                    <p>
                                        Our customer testimonials speak volumes. Organizations across industries trust us to enhance their data capabilities.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='d-flex shadow rounded justify-content-center align-items-center '>
                                <div>
                                    <img className='w-75' src={require('../Assets/AboutUS/Service_Level_Partner_Image.png')} alt='' />
                                </div>
                                <div className='benifits-frame-6'>
                                    <h6>Continuous Innovation:</h6>
                                    <p>
                                        We don’t rest on our laurels. Our R&D efforts ensure that we’re always at the forefront of data excellence.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>

            {/* Our Achievements starts */}
            <div className='m-3'>
                <div className='achive-top'>
                    <h2>Our Achievements</h2>
                    <p className='achive-empty' ></p>
                </div>
                <div className='m-4 py-4'>
                    <Container>
                        <Row>
                            <Col>
                                <div className='achive-content'>
                                    <img className='achiev-img1' src={require("../Assets/FooterImages/customer-first-gartner-blue.png")} alt='achieve1' />
                                    <p className='py-3'>Gartner Peer Insights
                                        “Customer First Vendor”
                                        for Master Data Management Solutions</p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <img className='achiev-img2' src={require("../Assets/HomePage/award-three.png")} alt='achieve1' />
                                    <p className='py-3'>“Voice of the Customer”
                                        Master Data Solutions
                                        Customers Choice Award 2022</p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <img className='achiev-img2' src={require("../Assets/HomePage/award-two.png")} alt='achieve1' />
                                    <p className='py-3'>Best Idea Award
                                        from our
                                        Global Business customer Vedanta</p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <img className='achiev-img3' src={require("../Assets/HomePage/gartner-peer-insights-2022.png")} alt='achieve1' />
                                    <p className='py-4'>Best performing companies 2020</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* Industry Recognition starts */}
            <div className='mt-4 '>
                <div data-aos="fade-right" className='d-flex ind-rec justify-content-center align-items-center'>
                    <h2>Industry Recognition</h2>
                    <p className='ind-empty d-flex ' ></p>
                </div>

                <Container data-aos="zoom-in" className='mt-4 py-4'>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={3} md={6} xs={8}>
                            <img className='ind-imgs' src={require('../Assets/HomePage/co-innovated-with-sap.png')} alt='ind-rec1' />
                        </Col>
                        <Col lg={3} md={6} xs={8}>
                            <img className='ind-imgs' src={require('../Assets/HomePage/sap-certified-integration-s4hana.png')} alt='ind-rec2' />
                        </Col>
                        <Col lg={3} md={6} xs={8}>
                            <img className='ind-imgs' src={require('../Assets/HomePage/sap-certified.png')} alt='ind-rec3' />
                        </Col>
                        <Col lg={3} md={6} xs={8}>
                            <img className='ind-imgs' src={require('../Assets/HomePage/sap-premium-certified.png')} alt='ind-rec4' />


                        </Col>
                    </Row>
                    <Row className=' mt-4 d-flex  justify-content-center'>
                        <Col lg={2} md={4} xs={8}>
                            <img className='ind-imgs1' src={require('../Assets/HomePage/integrated-with-oracle-cloud.png')} alt='ind-rec1' />
                        </Col>
                        <Col lg={2} md={4} xs={8}>
                            <img className='ind-imgs1' src={require('../Assets/HomePage/iso-27001.png')} alt='ind-rec2' />
                        </Col>
                        <Col lg={2} md={4} xs={8}>
                            <img className='ind-imgs1' src={require('../Assets/HomePage/iso-8000.png')} alt='ind-rec3' />
                        </Col>
                        <Col lg={2} md={4} xs={8}>
                            <img className='ind-imgs1' src={require('../Assets/HomePage/iso-9001.png')} alt='ind-rec4' />
                        </Col>
                        <Col lg={2} md={4} xs={8}>
                            <img className='ind-imgs1' src={require('../Assets/HomePage/powered-by-oracle-cloud.png')} alt='ind-rec4' />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* OUR CLIENTS */}
            <div>
                <Container>
                    <Carousel>
                        <Carousel.Item>

                        </Carousel.Item>
                    </Carousel>
                </Container>

            </div>

        </div>
    )
}
