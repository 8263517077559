import React from 'react'
import './NewsData.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ChampionInThe2023InfoTechSoftwareReviews = () => {
  return (
    <div>
        <div>
                <img className='w-100' src={require('../Assets/PublicationImages/NewsDataImages/Champion-in-2023-Master-Data-Management-Emotional-Footprint-Report-Banner.webp')} alt='ChampionInThe2023InfoTechSoftwareReviews-banner' />
            </div>

            <div className='ChampionInThe2023InfoTechSoftwareReviews-bg py-4 my-4'>
                <Container>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}} className='py-4'>
                        <Col lg={8}>
                        <div>
                            <h4 style={{lineHeight:1.8, color:'#0b4a99'}}>
                            PiLog once again named as a Champion in the 2023 InfoTech SoftwareReview's Emotional Footprint Report
                            </h4>
                            <p>PiLog, a leading MDM solutions provider across the globe, has been named Champion in the InfoTech SoftwareReview's <b>Emotional Footprint Award</b> for Master Data Management Solutions.</p>
                            <p>Throughout the 26 dimensions and evaluations of InfoTech SoftwareReviews, our solution MDRM for master data management has ranked top in vendor capabilities, product features, and overall satisfaction. The Emotional Footprint Quadrant not only assesses the technical prowess of software solutions but also evaluates their impact on users' emotions, satisfaction, and experience. <b>Download the report!</b></p>

                        </div>
                        </Col>
                        <Col>
                        <div>
                <img className='w-100' src={require('../Assets/PublicationImages/NewsDataImages/Master-Data-Management-EF.png')} alt='Master-Data-Management-EF.png' />
            </div>
                        </Col>
                    </Row>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}}>
                        <div>
                            <h4 style={{lineHeight:1.8, color:'#0b4a99'}}>A Champion in Emotional Footprint</h4>
                            <p>PiLog's recognition as a Champion in the Emotional Footprint Quadrant highlights its exceptional ability to not only meet but exceed the expectations of our valued clients. We provide innovative, user-centric solutions that empower businesses to excel in their data management endeavour's. SoftwareReviews sources genuine user feedback, prominently featuring the assessment and product ranking process guided by emotional response ratings.</p>
                            <Link><b>Click out here</b></Link>
                        </div>
                    </Row>
                </Container>
            </div>

            <div className='py-4 my-4'>
                <Container>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}} >
                        <div>
                            <h4 style={{lineHeight:1.8, color:'#0b4a99'}}>Key Factors that Led to Success</h4>
                            <h6>The key factors that contributed to MDRM's recognition as a Champion in the Emotional Footprint Quadrant include:</h6>
                        </div>
                    </Row>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}} className='py-4'>
                        <Col>
                        <div className='rounded-3  p-3' style={{background:'#cacff6', height:'10rem'}}>
                            <h5>
                            User-Centric Design
                            </h5>
                           <p>MDRM has been meticulously designed with users in mind, ensuring that it seamlessly aligns with the needs and preferences of our diverse user base.</p>
                        </div>
                        </Col>
                        <Col>
                        <div className='rounded-3  p-3' style={{background:'#cacff6', height:'10rem'}}>
                            <h5>
                            Reliability and Performance
                            </h5>
                            <p>
                            Our solution consistently delivers robust performance, enabling businesses to manage their Materials, Services, Assets, Vendors, Customers master data efficiently and with confidence.
                            </p>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}} className='py-4'>
                        <Col>
                        <div className='rounded-3  p-3' style={{background:'#cacff6', height:'10rem'}}>
                            <h5>
                            Customer SatisfactionCustomer Satisfaction
                            </h5>
                            <p>
                            The overwhelmingly positive feedback from our clients underscores their satisfaction with MDRM's functionality, ease of use, and its substantial impact on their operations.
                            </p>
                           </div>
                        </Col>
                        <Col>
                        <div className='rounded-3  p-3' style={{background:'#cacff6', height:'10rem'}}>
                            <h5>
                            Continuous Innovation
                            </h5>
                            <p>
                            We remain committed to investing in research and development, ensuring that MDRM remains at the forefront of the industry.
                            </p>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}} >
                        <div className='border border-primary rounded-4 p-4 m-3 '>
                            <p>MDRM solution scoring a <b>Net Emotional Footprint</b> of +95 with strong positive emotions around enabling productivity and helping its customers to innovate.</p>
                        </div>
                    </Row>
                    <Row style={{textAlign:'left', justifyContent:'center', alignItems:'center', lineHeight:2}} >
                        <div>
                            <h4 style={{lineHeight:1.8, color:'#0b4a99'}}>What prompted our clients to select PiLog MDRM?</h4>
                            <p>The PiLog MDRM system stands as a comprehensive Master Data Governance Solution encompassing a wide range of domains, including Material, Service, Vendor, Customer, Assets, Employee, and more, all enhanced by embedded Data Quality Management capabilities. This versatile, multi-domain, and multi-lingual solution is designed to effortlessly create and maintain all master data records within a unified installation. Among its core functions is Master Data Management, which encompasses governance processes tailored for creation, change, extension, deletion, and archiving, thereby effectively overseeing the entire lifecycle of master data records.</p>
                            <p>For more information about MDRM and how it can benefit your organization, please us at<b> info@piloggroup.org</b> or contact us directly</p>
                            <p><b>Phone: +91-40 66017722</b></p>
                            </div>
                    </Row>
                </Container>
            </div>
            
    </div>
  )
}
