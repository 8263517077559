import React from 'react'

export const WhatsApp = () => {
  return (
    <div>
        <img src={require('../Assets/Social Media-Icons/Social Media-Icons-04.png')}/>

    </div>
  )
}
