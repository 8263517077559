import React from 'react'

export const CallingIcon = () => {
  return (
    <div>
        <img style={{width:36}} src={require('../Assets/Social Media-Icons/Saas-Page-Icon-01.png')}/>
        
    </div>
  )
}
