import logo from './logo.svg';
import './App.css';
import { Header } from './Components/Header/Header';
import { TabComponent } from './Components/Header/TabComponent';
import { Footer } from './Components/Footer/Footer';
import { ValueTab } from './Components/Header/ValueTab';
import { Home } from './Components/HomePage/Home';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { Box } from '@mui/material';

import { Speeddial } from './Components/Speeddial/Speeddial';
import { Route, Routes } from 'react-router-dom';
import { ValuePropositions } from './Components/ValueProposition/ValuePropositions';
import { InnovativeProducts } from './Components/Innovative_Products/InnovativeProducts';
import { Services } from './Components/Services/Services';
import { Publications } from './Components/Publications/Publications';
import { ContactUs } from './Components/ContactUs/ContactUs';
import { ExtendedSupplyChainOptimization } from './Components/ValueProposition/ExtendedSupplyChainOptimization';
import { DataHarmonization } from './Components/Services/DataHarmonization';
import { DataAnalytics } from './Components/Services/DataAnalytics';
import { DataMigration } from './Components/Services/DataMigration';
import { DigitalTransformation } from './Components/Services/DigitalTransformation';
import { DataArchivingandUnarchiving } from './Components/Services/DataArchivingandUnarchiving';
import { ISManagement } from './Components/Services/ISManagement';
import { DataHealthAssessment } from './Components/Services/DataHealthAssessment';
import { PMDataAcquisition } from './Components/Services/PMDataAcquisition';
import ImagingTaggingInventoryOptimization from './Components/Services/ImagingTaggingInventoryOptimization';
import { PVorFDC } from './Components/Services/PVorFDC';
import { BPO } from './Components/Services/BPO';
import { ManagementConsulting } from './Components/Services/ManagementConsulting';
import { FixedAssetRegister } from './Components/Services/FixedAssetRegister';
import { DataConsultingServices } from './Components/Services/DataConsultingServices';
import { SpendAnalytics } from './Components/Services/SpendAnalytics';
import { MaterialCriticalityAnalysis } from './Components/Services/MaterialCriticalityAnalysis';
import { EquipmentCriticalityAnalysis } from './Components/Services/EquipmentCriticalityAnalysis';
import { MasterDataRecordManager } from './Components/Innovative_Products/MasterDataRecordManager';
import { EnduringAssetLifecycleManagement } from './Components/ValueProposition/EnduringAssetLifecycleManagement';
import { BusinessPartnerLifeCycleManagement } from './Components/ValueProposition/BusinessPartnerLifeCycleManagement';
import { SeamlessDataMigration } from './Components/ValueProposition/SeamlessDataMigration';
import { DataQualityManagement } from './Components/Innovative_Products/DataQualityManagement';
import { PiLogPreferredRecords } from './Components/ValueProposition/PiLogPreferredRecords';
import { DataQualityHub } from './Components/Innovative_Products/DataQualityHub';
import { PiLogPreferredHierarchy } from './Components/Innovative_Products/PiLogPreferredHierarchy';
import MasterDataOntologyManager from './Components/Innovative_Products/MasterDataOntologyManager';
import { SparePartsInterchangeabilityRecord } from './Components/Innovative_Products/SparePartsInterchangeabilityRecord';
import { TransformETL } from './Components/Innovative_Products/TransformETL';
import PiLogPreferredOntology from './Components/Innovative_Products/PiLogPreferredOntology';
import { MaterialMasterTaxonomy } from './Components/Innovative_Products/MaterialMasterTaxonomy';
import { Coswin } from './Components/Innovative_Products/Coswin';
import { LeanDataGovernance } from './Components/Innovative_Products/LeanDataGovernance';
import { Videos } from './Components/Publications/Videos';
import { SaaS } from './Components/SaaSPage/SaaS';
import { Brochure } from './Components/Publications/Brochure';
import { Blogs } from './Components/Publications/Blogs';
import { NewsAndUpdates } from './Components/Publications/NewsAndUpdates';
import { HonorAwardsInInformationTechnology } from './Components/NewsData/HonorAwardsInInformationTechnology';
import { DataManagementThinkLab } from './Components/NewsData/DataManagementThinkLab';
import { MasterDataManagement } from './Components/NewsData/MasterDataManagement';
import { Corporate } from './Components/AboutUS/Corporate';
import { ZonalOffices } from './Components/AboutUS/ZonalOffices';
import { IndustriesCustomers } from './Components/AboutUS/IndustriesCustomers';
import { Partners } from './Components/AboutUS/Partners';
import { PartnerProgram } from './Components/AboutUS/PartnerProgram';
import { SapSilverPartner } from './Components/AboutUS/SapSilverPartner';
import { OraclePartnerNetwork } from './Components/AboutUS/OraclePartnerNetwork';
import { SivecoGroupCoswin } from './Components/AboutUS/SivecoGroupCoswin';
import { AdminForm } from './Components/AdminForm/AdminForm';
import { LeadershipTeam } from './Components/AboutUS/LeadershipTeam';
import { GulfHR } from './Components/AboutUS/GulfHR';
import { CoswinNomad } from './Components/Innovative_Products/Coswin/CoswinNomad';
import { CoswinOpen } from './Components/Innovative_Products/Coswin/CoswinOpen';
import { CoswinSmartGeneration } from './Components/Innovative_Products/Coswin/CoswinSmartGeneration';
import { Coswin8i } from './Components/Innovative_Products/Coswin/Coswin8i';
import { PiLogAppointsFormerSAPAndOracleExecutive } from './Components/NewsData/PiLogAppointsFormerSAPAndOracleExecutive';
import { TheEmiratesTimesShinesOnImadSyed } from './Components/NewsData/TheEmiratesTimesShinesOnImadSyed';

import { TopHeader } from './Components/Header/TopHeader';
import { ChampionInThe2023InfoTechSoftwareReviews } from './Components/NewsData/ChampionInThe2023InfoTechSoftwareReviews';
import { DisplayForm } from './Components/AdminForm/DisplayForm';
import { APITesting } from './Components/AdminForm/APITesting';
import { AboutUs } from './Components/AboutUS/AboutUs';
import { Helmet } from 'react-helmet';




function App() {
  const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
  ];
  return (
    <div className="App">
      <Helmet>
      <meta name = "ROBOTS" content = "noindex,nofollow"/>
      </Helmet>
      
      <header >
        
        {/* <TopHeader/> */}
        <Header/>
      </header>
      <br/>
      <br/>
      <br/>
        
      <main>
       
       
        
       {/* <Home/> */}
      <div>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='about-us' element = {<AboutUs/>}/>
          <Route path='admin-form' element={<AdminForm/>}/>
          <Route path='display-form' element={<DisplayForm/>}/>
         <Route path='api-test' element={<APITesting/>}/>
          <Route path='about/corporate' element={<Corporate/>}/>
          <Route path='about/zonal-offices' element={<ZonalOffices/>}/>
          <Route path='about/industries-And-Customers' element={<IndustriesCustomers/>}/>
          <Route path='about/partners' element={<Partners/>}/>
          <Route path='about/partners/sap-silver-partner' element={<SapSilverPartner/>}/>
          <Route path='about/partners/oracle-partner-network' element={<OraclePartnerNetwork/>}/>
          <Route path='about/partners/siveco-group-coswin' element={<SivecoGroupCoswin/>}/>
          <Route path='about/partners/gulf-hr' element={<GulfHR/>}/>
          <Route path='about/partner-program' element={<PartnerProgram/>}/>
          <Route path='about/leadership-team' element={<LeadershipTeam/>}/>
          <Route path='valuepropositons' element={<ValuePropositions/>}/>
          <Route path='value-propositons/extended-supply-chain-optimization' element={<ExtendedSupplyChainOptimization/>}/>
          <Route path='value-propositons/enduring-asset-lifecycle-management' element={<EnduringAssetLifecycleManagement/>}/>
          <Route path='value-propositons/business-partner-lifecycle-management' element={<BusinessPartnerLifeCycleManagement/>}/>
          <Route path='value-propositions/seamless-data-migration-and-effective-application-consolidation' element={<SeamlessDataMigration/>}/>
          <Route path='innovative-products' element={<InnovativeProducts/>}/>
          <Route path='innovative-products/master-data-record-manager' element={<MasterDataRecordManager/>}/>
          <Route path='innovative-products/data-quality-hub' element={<DataQualityHub/>}/>
          <Route path='innovative-products/data-quality-management' element={<DataQualityManagement/>}/>
          <Route path='innovative-products/master-data-ontology-manager' element={<MasterDataOntologyManager/>}/>
          <Route path='innovative-products/piLog-preferred-records' element={<PiLogPreferredRecords/>}/>
          <Route path='innovative-products/data-quality-management/piLog-preferred-records' element={<PiLogPreferredRecords/>}/>
          <Route path='innovative-products/data-quality-management/piLog-preferred-ontology' element={<PiLogPreferredOntology/>}/>
          <Route path='innovative-products/piLog-preferred-ontology' element={<PiLogPreferredOntology/>}/>
          <Route path='innovative-products/piLog-preferred-hierarchy' element={<PiLogPreferredHierarchy/>}/>
          <Route path='innovative-products/data-quality-management/piLog-preferred-hierarchy' element={<PiLogPreferredHierarchy/>}/>
          <Route path='innovative-products/spare-parts-interchange-ability-record' element={<SparePartsInterchangeabilityRecord/>}/>
          <Route path='innovative-products/i-transform-etl' element={<TransformETL/>}/>
          <Route path='innovative-products/material-master-taxonomy' element={<MaterialMasterTaxonomy/>}/>
          
          <Route path='innovative-products/coswin' element={<Coswin/>}/>
          <Route path='innovative-products/coswin/coswin-nomad' element={<CoswinNomad/>}/>
          <Route path='innovative-products/coswin/coswin-open' element={<CoswinOpen/>}/>
          <Route path='innovative-products/coswin/coswin-smart-generation' element={<CoswinSmartGeneration/>}/>
          <Route path='innovative-products/coswin/coswin8i' element={<Coswin8i/>}/>

          <Route path='innovative-products/lean-data-governance' element={<LeanDataGovernance/>}/>


          <Route path='services' element={<Services/>}/>
          <Route path ='services/data-harmonization' element={<DataHarmonization/>}/>          
          <Route path ='services/data-analytics' element={<DataAnalytics/>}/>
          <Route path ='services/data-migration' element={<DataMigration/>}/>
          <Route path ='services/digital-transformation' element={<DigitalTransformation/>}/>  
          <Route path ='services/ismanagement' element={<ISManagement/>}/>   
          <Route path='services/data-health-assessment' element={<DataHealthAssessment/>}/>   
          <Route path ='services/data-archivingand-unarchiving' element={<DataArchivingandUnarchiving/>}/>
          <Route path='services/pmdata-acquisition' element ={<PMDataAcquisition/>}/>
          <Route path='services/imaging-tagging-inventory-optimization' element={<ImagingTaggingInventoryOptimization/>}/>
          <Route path='#' element={<BPO/>}/>
          <Route path='services/management-consulting' element={<ManagementConsulting/>}/>
          <Route path='services/fixed-asset-register' element={<FixedAssetRegister/>}/>
          <Route path='services/data-consulting-services' element={<DataConsultingServices/>}/>
          <Route path='services/material-criticality-analysis' element={<MaterialCriticalityAnalysis/>}/>
          <Route path='services/equipment-criticality-analysis' element={<EquipmentCriticalityAnalysis/>}/>
          <Route path='services/spend-analytics' element={<SpendAnalytics/>}/>
          <Route path='services/physical-verification-field-data-collection' element={<PVorFDC/>}/>
          <Route path='publications' element={<Publications/>}/>
          <Route path='publications/videos' element={<Videos/>}/>
          <Route path='publications/brochure' element={<Brochure/>}/>
          <Route path='publications/blogs' element={<Blogs/>}/>
          <Route path='publications/news-and-updates' element={<NewsAndUpdates/>}/>
          <Route path='publications/news-and-updates/thinklinkers-9th-data-management-thinkLab' element={<DataManagementThinkLab/>}/>
          <Route path='publications/news-and-updates/HonorAwardsInInformationTechnology' element={<HonorAwardsInInformationTechnology/>}/>
          <Route path='publications/news-and-updates/master-data-management-big-data-analytics-blog' element={<MasterDataManagement/>}/>
          <Route path='publications/news-and-updates/PiLog-Appoints-Former-SAP-And-Oracle-Executive' element={<PiLogAppointsFormerSAPAndOracleExecutive/>}/>
          <Route path='publications/news-and-updates/the-emirates-times-shines-on-imad-syed' element={<TheEmiratesTimesShinesOnImadSyed/>}/>
          <Route path='publications/news-and-updates/champion-in-the-2023-infotech-softwarereviews' element={<ChampionInThe2023InfoTechSoftwareReviews/>}/>


          <Route path='contactus' element={<ContactUs/>}/>
          <Route path='Subscription' element={<SaaS/>}/>
          

        </Routes>
      </div>
     
      </main>
      <div className="icon-bar">
  <div className='sticky-icon'>
    <Speeddial/>
   
  </div>
</div>
      <footer>
      <Footer/>
      </footer>
      
    </div>
  );
}

export default App;
