
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import axios from 'axios';

export const DisplayForm = () => {

    const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`http://192.168.1.91:3306/getdata/499B784BD2FD4487A7D619DD6204D98A`);
        setPost(response.data);
        console.log("response.data", response.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, []);

  return (
    <div>
        <div>
      {post && (
        <div className='w-50'>
          <h2>{post.title}</h2>
          <p>{post.description}</p>
          {post.SECTION1_IMAGE && <img className='w-50' src={`data:image/jpeg;base64,${post.SECTION1_IMAGE}`} alt="Section 1" />}
          {post.SECTION2_IMAGE && <img className='w-50' src={`data:image/jpeg;base64,${post.SECTION2_IMAGE}`} alt="Section 2" />}
          {post.SECTION3_IMAGE && <img src={`data:image/jpeg;base64,${post.SECTION3_IMAGE}`} alt="Section 3" />}
        </div>
        

      )}
    </div>
        
    </div>
  )
}
